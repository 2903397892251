<template>
    <div>
        <label v-if="label" :for="id" :class="[{ required: required }, 'form-label']">
            {{ label }}
        </label>

        <div class="relative">
            <input
                :id="id"
                ref="input"
                type="text"
                v-bind="$attrs"
                :class="[{ error: error, disabled: readOnly }, 'form-input']"
                :disabled="readOnly"
                v-model="phoneNumber"
            />

            <div v-if="error" class="form-trailing-icon">
                <WarningCircle class="h-4 w-4 text-red-500" aria-hidden="true" />
            </div>
        </div>
        <Error :message="error" />
    </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { WarningCircle } from '@iconoir/vue';
import Error from './Error.vue';
import { AsYouType } from 'libphonenumber-js';

defineOptions({
    inheritAttrs: false,
});

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    id: String,
    modelValue: {
        type: String,
        default: '',
    },
    label: String,
    error: String,
    required: {
        type: Boolean,
        default: false,
    },
    readOnly: {
        type: Boolean,
        default: false,
    },
});

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });

const phoneNumber = ref(props.modelValue);

const phone = computed(() => {
    const asYouType = new AsYouType('CA');
    asYouType.input(phoneNumber.value);

    return {
        country: asYouType.getCountry(),
        formattedOutput: asYouType.formattedOutput,
        e164Number: asYouType.getNumberValue(),
        national: asYouType.getNationalNumber(),
        valid: asYouType.isValid() ? 'true' : 'false',
    };
});

watch(phoneNumber, () => {
    emit('update:modelValue', phone.value.e164Number);
    phoneNumber.value = phone.value.formattedOutput;
});
</script>
